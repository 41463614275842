
import Vue, { PropType } from "vue";
import { DialogCloseType, DialogI } from "@/types/dialog";
import BaseDialog from "@/components/dialog/BaseDialog.vue";
import Btn from "@/components/v2/base/Btn.vue";

interface TreeviewItem {
  id: string;
  label: string;
  children?: TreeviewItem[];
}

export default Vue.extend({
  name: "UserProfileDeleteProgressDialog",
  components: {
    BaseDialog,
    Btn,
  },
  props: {
    dialog: {
      type: Object as PropType<DialogI>,
      required: true,
    },
  },
  data: () => ({
    selectedLessons: [] as string[],
  }),
  methods: {
    closeDialog() {
      this.dialog.close({
        type: DialogCloseType.CLOSED,
      });
    },
    deleteProgress() {
      this.dialog.close({
        type: DialogCloseType.SUBMITTED,
        payload: {
          selectedLessons: this.selectedLessons,
        },
      });
    },
  },
  computed: {
    items(): TreeviewItem[] {
      return (
        this.dialog.config.payload?.progress.map(({ course, lessons }) => {
          return {
            id: course._id,
            name: course.name,
            children: lessons.map((lessonItem) => ({
              id: lessonItem._id,
              name: lessonItem.name,
            })),
          };
        }) || []
      );
    },
  },
});
